var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.educationalGroupsData),function(educationalGroup,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":educationalGroup.educationalGroupNameCurrent,"description":educationalGroup.fullCode,"imagePath":educationalGroup.educationalGroupImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(_vm.checkPrivilege(_vm.hasEducationalScheduleTime()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'EducationalScheduleTimes',
          params: {
            educationalGroupToken: educationalGroup.educationalGroupToken,
          },
        },"title":_vm.$t('EducationalScheduleTimes.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/EducationalScheduleTimes.svg")}})])],1):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalGroupStudent()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'EducationalGroupStudents',
          params: {
            educationalGroupToken: educationalGroup.educationalGroupToken,
            educationalCategoryToken:
              educationalGroup.educationalCategoryToken,
          },
        },"title":_vm.$t('EducationalGroupStudents.studentsData')}},[_c('img',{attrs:{"src":require("@/assets/images/students.svg")}})])],1):_vm._e(),(_vm.checkPrivilege(_vm.hasStorageSpaceEducationalGroup()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'StorageSpacesEducationalGroup',
          params: {
            mainToken: educationalGroup.educationalGroupToken,
          },
        },"title":_vm.$t('StorageSpaces.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/StorageSpaces.svg")}})])],1):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalRating()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'EducationalGroupsRatings',
          params: {
            educationalGroupToken: educationalGroup.educationalGroupToken,
          },
        },"title":_vm.$t('EducationalRatings.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/educationalRatings.svg")}})])],1):_vm._e(),(_vm.checkPrivilege(_vm.hasGroupScheduleExam()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'GroupScheduleExams',
          params: {
            educationalGroupToken: educationalGroup.educationalGroupToken,
          },
        },"title":_vm.$t('GroupScheduleExams.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/GroupScheduleExams.svg")}})])],1):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalGroupTransferAdd()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('EducationalGroupTransfers.add')},on:{"click":function($event){_vm.setEducationalGroupData(educationalGroup);
          _vm.openBottomSheet('EducationalGroupTransferAdd');}}},[_c('img',{attrs:{"src":require("@/assets/images/EducationalGroupTransfers.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalGroupAttendance()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'EducationalGroupAttendances',
          params: {
            educationalGroupToken: educationalGroup.educationalGroupToken,
          },
        },"title":_vm.$t('EducationalGroupAttendances.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/EducationalGroupAttendances.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setEducationalGroupData(educationalGroup);
          _vm.openBottomSheet('EducationalGroupInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setEducationalGroupData(educationalGroup);
          _vm.openBottomSheet('EducationalGroupQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasEducationalGroupEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setEducationalGroupData(educationalGroup);
          _vm.openBottomSheet('EducationalGroupUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalGroupFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalGroupDelete",modifiers:{"EducationalGroupDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEducationalGroupData(educationalGroup)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalGroupChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalGroupChangeActivationType",modifiers:{"EducationalGroupChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setEducationalGroupData(educationalGroup)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setEducationalGroupData(educationalGroup);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }